<template>
    <div class="wrapper">
        <div class="">
            <BindThirdPartyAccountForm>
                <template slot="title">
                    <span style="font-size: 18px; font-weight: bold;">{{$t('login.bindThirdPartyAccounts')}}</span>
                </template>
            </BindThirdPartyAccountForm>
        </div>
    </div>
</template>

<script>
    import BindThirdPartyAccountForm from "../../../components/login/BindThirdPartyAccountForm";
    export default {
        name: "BindThirdPartyAccount",
        components: {BindThirdPartyAccountForm}
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    @input-width: 400px;
    .wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .top {
            width: @input-width;
        }
    }
</style>
