<template>
    <div>
        <el-form :model="bindThirdPartyAccountForm" ref="bindThirdPartyAccountForm" :rules="rules">
            <el-form-item>
                <slot name="title"></slot>
            </el-form-item>
            <el-form-item prop="account">
                <el-input class="input" v-model="bindThirdPartyAccountForm.account" placeholder="手机号或者邮箱" round/>
            </el-form-item>
            <el-form-item prop="code">
                <el-row>
                    <el-input class="input2" v-model="bindThirdPartyAccountForm.code" placeholder="请输入验证码" round>
                        <template slot="append">
                            <div class="pointer" v-if="securityCodeTimeOut === 0" @click="checkUser">发送验证码</div>
                            <div class="pointer" v-if="securityCodeTimeOut !== 0">{{securityCodeTimeOut}}s重试</div>
                        </template>
                    </el-input>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-button @click="binding" type="primary" class="binding">绑定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {commitMsg} from "../../request/api/smsc.js";
    import rules from "../../views/login/js/rules";
    import ThirdPartyLogin from "./ThirdPartyLogin.vue";
    import Language from "./Language.vue";
    import {checkUser} from "../../request/api/merchant.js";
    import {commonBinding} from "../../request/api/merchant";

    export default {
        name: "BindThirdPartyAccountForm",
        components: {Language, ThirdPartyLogin},
        data(){
            return {
                rules: rules.rules,
                securityCodeTimeOut: 0,
                bindThirdPartyAccountForm: {
                    account:'',
                    code: '',
                },
                emailAndMobile: '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])|^(?:0|86|\\+86)?1[3-9]\\d{9}$',
            }
        },
        methods: {
            checkUser() {
                let account = this.bindThirdPartyAccountForm.account;
                let _this = this;
                if (account && account.match(this.emailAndMobile)) {
                    let data = {
                        'account': account,
                        'exist': true,
                    };
                    checkUser(data).then((result) => {
                        if (result.code === 200){
                            _this.commitMsg()
                        }else {
                            _this.$notify({
                                title: _this.$t('login.hint'),
                                message: result.message,
                                type: 'warning'
                            });
                        }
                    })
                }
            },
            commitMsg() {
                let data = {
                    'toFrom': this.bindThirdPartyAccountForm.account,
                    'typeNum': 1,
                };
                let _this = this;
                commitMsg(data).then(result => {
                    if (result.code === 200){
                        _this.securityCodeTimeOut = 60;
                        let interval = setInterval(() => {
                            if (_this.securityCodeTimeOut > 0){
                                _this.securityCodeTimeOut -= 1;
                            }else {
                                clearInterval(interval)
                            }
                        },1100);
                        _this.$notify({
                            title: _this.$t('login.succeed'),
                            message: _this.$t('login.captchaCodeSentSuccessfully'),
                            type: 'success'
                        });
                    }else {
                        _this.$notify.error({
                            title: _this.$t('login.error'),
                            message: result.message
                        });
                    }
                })
            },
            binding(){
                this.$refs["bindThirdPartyAccountForm"].validate((val) => {
                    if (val) {
                        let data = {
                            'account':this.bindThirdPartyAccountForm.account,
                            'code':this.bindThirdPartyAccountForm.code,
                        };
                        let _this = this;
                        commonBinding(data).then((result) => {
                            if (result.code === 200){
                                _this.$notify({
                                    title: _this.$t('login.succeed'),
                                    message: result.message,
                                    type: 'success'
                                });
                            }else {
                                _this.$notify({
                                    title: _this.$t('login.hint'),
                                    message: result.message,
                                    type: 'warning'
                                });
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index.less";
    @input-width: 308px;
    .input {
        width: 308px;
        height: 40px;
    }
    .input2 {
        width: 308px;
        height: 40px;

        &/deep/ .el-input__inner{
            border-right: 0px;
        }
        &/deep/ .el-input-group__append {
            background-color: white;
            color: @color-primary;
        }
    }
    .binding {
        width: @input-width;
        text-align: center;
        cursor: pointer;
    }
    .pointer {
        cursor: pointer;
    }
</style>
